import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import vimeo from '../img/social/vimeo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <div className="pt-8">
        <section className="bg-dark border-multicolor">
          <div className="container-lg">
            <div className="row align-items-center">
              <div className="col-12">

                <hr className="border-gray-900 mt-0 mb-8" />

              </div>
              <div className="col-md">

                <h1 className="text-white mb-5">
                  Sign up for our <span className="text-warning">WFH Survival Guide</span>
                </h1>

              </div>
              <div className="col-md-4">
                <form action="https://renocollective.us2.list-manage.com/subscribe/post?u=809164e750881a9f0af4bc99d&amp;id=63804cf2f8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" noValidate>
                  <input className="form-control" type="email" placeholder="Your email address" name="EMAIL" id="mce-EMAIL"/>
                </form>
              </div>
              <div className="col-12">
                <hr className="border-gray-900 mb-0 mt-8" />
              </div>
            </div>
          </div>
        </section>
        <footer className="footer py-8 pt-md-11 bg-dark">
          <div className="container-lg">
          <div className="row">
            <div className="col-md-4">
              <h2 className="font-family-serif text-white mb-1">
                Reno Collective
              </h2>
              <p className="text-white-60">
                An coworking space and community of freelancers, startups, remote employees, and independent workers in Reno, NV.
              </p>
            </div>
            <div className="col-7 col-md">
              <h6 className="text-uppercase text-white mb-3 mb-md-5">
                Join us on your favorites
              </h6>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <a className="text-white-60" href="https://facebook.com/renocollective" target="_blank">RC on Facebook</a>
                </li>
                <li className="mb-3">
                  <a className="text-white-60" href="https://twitter.com/renocollective" target="_blank">RC on Twitter</a>
                </li>
                <li className="mb-3">
                  <a className="text-white-60" href="https://instagram.com/renocollective" target="_blank">RC on Instagram</a>
                </li>
                <li className="mb-3">
                  <a className="text-white-60" href="https://www.linkedin.com/company/reno-collective" target="_blank">RC on LinkedIn</a>
                </li>
                <li className="mb-3">
                  <a className="text-white-60" href="https://www.youtube.com/channel/UC_yjpdGV5Xq0qTEZujSxQAA" target="_blank">RC on YouTube</a>
                </li>
                <li className="mb-3">
                  <a className="text-white-60" href="https://twitch.tv/renocollective" target="_blank">RC on Twitch</a>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md">
              <h6 className="text-uppercase text-white mb-3 mb-md-5">
                Dive Deep
              </h6>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <Link to="/community" className="text-white-60" title="About Reno Collective">About Us</Link>
                </li>
                <li className="mb-3">
                  <Link to="/faqs" className="text-white-60" title="FAQs">FAQs</Link>
                </li>
                <li className="mb-3">
                  <Link to="/blog" className="text-white-60" title="Our Blog">RC Blog</Link>
                </li>
                <li className="mb-3">
                  <Link to="/contact" className="text-white-60" title="Contact Us">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="border-white-10 my-7" />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <ul className="list-inline list-unstyled text-gray-600 small mb-md-0">
                <li className="list-inline-item">
                  © 2021 Reno Collective
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-auto">
              <ul className="list-inline list-unstyled text-gray-600 mb-0">
                <li className="list-inline-item">
                  <a className="icon icon-sm text-reset" href="https://instagram.com/renocollective" target="_blank">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0V0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z" fill="currentColor"/>
                    </svg>
                  </a>
                </li>
                <li className="list-inline-item ml-1">
                  <a className="icon icon-sm text-reset" href="https://facebook.com/renocollective" target="_blank">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.675 0H1.325C0.593 0 0 0.593 0 1.325V22.676C0 23.407 0.593 24 1.325 24H12.82V14.706H9.692V11.084H12.82V8.413C12.82 5.313 14.713 3.625 17.479 3.625C18.804 3.625 19.942 3.724 20.274 3.768V7.008L18.356 7.009C16.852 7.009 16.561 7.724 16.561 8.772V11.085H20.148L19.681 14.707H16.561V24H22.677C23.407 24 24 23.407 24 22.675V1.325C24 0.593 23.407 0 22.675 0V0Z" fill="currentColor"/>
                    </svg>
                  </a>
                </li>
                <li className="list-inline-item ml-1">
                  <a className="icon icon-sm text-reset" href="https://twitter.com/renocollective" target="_blank">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 4.55705C23.117 4.94905 22.168 5.21305 21.172 5.33205C22.189 4.72305 22.97 3.75805 23.337 2.60805C22.386 3.17205 21.332 3.58205 20.21 3.80305C19.313 2.84605 18.032 2.24805 16.616 2.24805C13.437 2.24805 11.101 5.21405 11.819 8.29305C7.728 8.08805 4.1 6.12805 1.671 3.14905C0.381 5.36205 1.002 8.25705 3.194 9.72305C2.388 9.69705 1.628 9.47605 0.965 9.10705C0.911 11.388 2.546 13.522 4.914 13.997C4.221 14.185 3.462 14.229 2.69 14.081C3.316 16.037 5.134 17.46 7.29 17.5C5.22 19.123 2.612 19.848 0 19.54C2.179 20.937 4.768 21.752 7.548 21.752C16.69 21.752 21.855 14.031 21.543 7.10605C22.505 6.41105 23.34 5.54405 24 4.55705Z" fill="currentColor"/>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
    )
  }
}

export default Footer
